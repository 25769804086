<template>
  <div>
    <div class="p-3 mb-2 bg-dark bg-gradient text-white">
  <h4>오늘 도착 오픈 기념</h4>
  <h4>배송비 무료 이벤트!</h4>
</div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      sampleData: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
<style scoped>

</style>
