import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BakerHomeView from '@/views/Bakery/BakeryHomeView.vue'
import BlogHomeView from '@/views/Blog/BlogHomeView.vue'
import BlogTap1View from '@/views/Blog/BlogTap1View.vue'
import BlogTap2View from '@/views/Blog/BlogTap2View.vue'
import BlogTap3View from '@/views/Blog/BlogTap3View.vue'
import BlogTap4View from '@/views/Blog/BlogTap4View.vue'
import BlogTap5View from '@/views/Blog/BlogTap5View.vue'
import BakeryTap1View from '@/views/Bakery/BakeryTap1View.vue'
import BakeryTap2View from '@/views/Bakery/BakeryTap2View.vue'
import BakeryTap3View from '@/views/Bakery/BakeryTap3View.vue'
import BakeryLoginView from '@/views/Bakery/BakeryLoginView.vue'
import BakeryCartView from '@/views/Bakery/BakeryCartView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/bakery',
    name: 'bakeryhome',
    component: BakerHomeView,
    redirect: 'bakery/tap2',
    children: [
      {
        path: 'tap1',
        name: 'bakerytap1',
        component: BakeryTap1View
      },
      {
        path: 'tap2',
        name: 'bakerytap2',
        component: BakeryTap2View
      },
      {
        path: 'tap3',
        name: 'bakerytap3',
        component: BakeryTap3View
      },
      {
        path: 'login',
        name: 'bakerylogin',
        component: BakeryLoginView
      },
      {
        path: 'cart',
        name: 'bakerycart',
        component: BakeryCartView
      }
    ]
  },
  {
    path: '/blog',
    name: 'bloghome',
    component: BlogHomeView,
    redirect: 'blog/tap1',
    children: [
      {
        path: 'tap1',
        name: 'blogtap1',
        component: BlogTap1View
      },
      {
        path: 'tap2',
        name: 'blogtap2',
        component: BlogTap2View
      },
      {
        path: 'tap2/:id',
        name: 'blogtap2id',
        component: BlogTap2View
      },
      {
        path: 'tap3',
        name: 'blogtap3',
        component: BlogTap3View
      },
      {
        path: 'tap3/:id',
        name: 'blogtap3id',
        component: BlogTap2View
      },
      {
        path: 'tap4',
        name: 'blogtap4',
        component: BlogTap4View
      },
      {
        path: 'tap4/:id',
        name: 'blogtap4id',
        component: BlogTap2View
      },
      {
        path: 'tap5',
        name: 'blogtap5',
        component: BlogTap5View
      }
    ]
  },
  {
    // path: 'blog/tap2',
    // name: 'blogtap2',
    // component: BlogTap2View
  // },
  // {
    // path: 'blog/tap3',
    // name: 'blogtap3',
    // component: BlogTap3View
  // },
  // {
    // path: 'blog/tap4',
    // name: 'blogtap4',
    // component: BlogTap4View
  // },
  // {
    // path: 'blog/tap5',
    // name: 'blogtap5',
    // component: BlogTap5View
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
