<template>
  <div class="bg-black">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button @click="this.$router.replace('tap1')"
    class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
    타인 관찰
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button @click="this.$router.replace('tap2')"
    class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
    타인 관찰
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button @click="this.$router.replace('tap3')"
    class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
    나 혼자 논다
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button @click="this.$router.replace('tap4')"
    class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
    유용한 사이트
  </button>
  </li>
  <li class="nav-item" role="presentation">
    <button @click="this.$router.replace('tap5')"
    class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
    은밀한 취미
  </button>
  </li>
</ul>

  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sampleData: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<style scoped>
.nav-item{
color : secondary;
}
.nav {
  --bs-nav-link-color: #f8f9fa;
  --bs-nav-link-hover-color: gray-800;
  }
.nav-tabs {
  --bs-nav-tabs-link-active-bg: #secondary;
}
.--bs-nav-tabs-link-active-bg{
  color:secondary;
}

</style>
