<template>
  <div class="row g-0 text-center">
    <div class="row">
      <div class="col-6 col-md-8"></div>
      <div class="col-sm-6 col-md-4">
  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
  <label class="btn btn-outline-dark" for="btnradio1">인기 ▲</label>

  <input @click="$store.commit('priceSort')" type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
  <label class="btn btn-outline-dark" for="btnradio2">가격 ▼</label>

  <input @click="$store.commit('invenSort')" type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
  <label class="btn btn-outline-dark" for="btnradio3">재고 ▼</label>
</div>
</div>
  </div>

  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sampleData: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<style scoped>
.btn-group{
  padding-bottom: 10px;
  position: right;
}
</style>
