<template>
  <div>
    <h1>은밀한 취미탭</h1>
    <button @click="misscat(), missdog(), missfox(), misssiba()">몰래 보세요</button>
    <!-- <div class="ratio ratio-16x9">
    <img :src="sampleData">
    </div>
    <div class="ratio ratio-16x9">
    <img :src="sibaData">
    </div>
    <div class="ratio ratio-16x9">
    <img :src="foxData">
    </div>
    <div class="ratio ratio-16x9">
    <img :src="dogData">
    </div> -->

    <div class="row row-cols-1 row-cols-md-2 g-4">
  <div class="col">
    <div class="card h-100">
      <img :src="sampleData" class="card-img-top" alt="sampleData">
      <!-- <div class="card-body"> -->
        <!-- <h5 class="card-title">Card title</h5> -->
        <!-- <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> -->
      <!-- </div> -->
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <img :src="sibaData" class="card-img-top" alt="sibaData">
      <!-- <div class="card-body"> -->
        <!-- <h5 class="card-title">Card title</h5> -->
        <!-- <p class="card-text">This is a short card.</p> -->
      <!-- </div> -->
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <img :src="foxData" class="card-img-top" alt="foxData">
      <!-- <div class="card-body"> -->
        <!-- <h5 class="card-title">Card title</h5> -->
        <!-- <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p> -->
      <!-- </div> -->
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <img :src="dogData" class="card-img-top" alt="dogData">
      <!-- <div class="card-body"> -->
        <!-- <h5 class="card-title">Card title</h5> -->
        <!-- <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> -->
      <!-- </div> -->
    </div>
  </div>
</div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  components: {},
  data() {
    return {
      sampleData: '',
      dogData: '',
      sibaData: '',
      foxData: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    misscat() {
      axios.get('https://aws.random.cat/meow')
        .then((heycat) => {
          console.log(heycat.file)
          this.sampleData = heycat.data.file
        })
    },
    missdog() {
      axios.get('https://random.dog/woof.json')
        .then((heydog) => {
          console.log(heydog.file)
          this.dogData = heydog.data.url
        })
    },
    misssiba() {
      axios.get('https://shibe.online/api/shibes?count=1&urls=true&httpsUrls=true')
        .then((heysiba) => {
          console.log(heysiba.file)
          this.sibaData = heysiba.data
        })
    },
    missfox() {
      axios.get('https://randomfox.ca/floof/')
        .then((heyfox) => {
          console.log(heyfox.file)
          this.foxData = heyfox.data.image
        })
    }
  }
}
</script>
<style scoped>
.col {
  width: 50%;
  height: 50%;
}
</style>
