<template>
<div>
        <span class="fw-bold fs-1" @click="naveropen()" > NEVER </span>
      <!-- <form name=fsearch method=get style="margin:10px;" action="http://search.naver.com/search.naver">
    <span class="input-group mb-4" style="padding-left:18%;padding-right:18%">
    <input type=hidden name=where value="nexteach">
    <input type="text" name=query itemname="검색어" required value="" class="form-control" aria-label="" aria-describedby="button-addon2">
    <button type="submit" id="button-addon2">🔍</button>
      </span>
      </form>
      </div> -->
  <form name=fsearch method=get style="margin:10px;" action="http://search.naver.com/search.naver">
  <div class="input-group mb-3" style="padding-left:18%;padding-right:18%">
  <input type=hidden name=where value="nexteach">
  <input type="text" name=query itemname="검색어" class="form-control" aria-describedby="button-addon2">
  <button class="btn-btn-success" type="submit" id="button-addon2">🔍</button>
  </div>
  </form>
  <p class="text-end fw-bold">지금 서울 날씨 : {{ temple }} <button @click="howcold" type="button" class="btn btn-outline-success">검색</button></p>
  </div>
<Slide/>

</template>

<script>
import Slide from '@/components/Slide.vue'
import axios from 'axios'
export default {
  name: 'HomeView',
  data() {
    return {
      temple: ''
    }
  },
  components: {
    Slide
  },
  methods: {
    naveropen: function () {
      window.open('https://naver.com', '_blank')
    },
    howcold() {
      axios.get('https://goweather.herokuapp.com/weather/seoul')
        .then((heyhi) => {
          console.log(heyhi)
          this.temple = heyhi.data.temperature
        })
    }
  }
}
</script>
<style scoped>

.btn-outline-secondary {
    --bs-btn-color: #5cb85c;
    --bs-btn-border-color: #5cb85c;
    --bs-btn-hover-color: #5cb85c;
    --bs-btn-hover-bg: #5cb85c;
    --bs-btn-hover-border-color: #5cb85c;
    --bs-btn-focus-shadow-rgb: 108,117,125;
    --bs-btn-active-color: #5cb85c;
    --bs-btn-active-bg: #5cb85c;
    --bs-btn-active-border-color: #5cb85c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #5cb85c;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #5cb85c;
    --bs-gradient: none;
}
.form-control {
    /* display: block;
    width: 100%; */
    /* padding: 0.375rem 0.75rem; */
    /* font-size: 1rem;
    font-weight: 400;
    line-height: 1.5; */
    color: #212529;
    background-color: #fff;
    /* background-clip: padding-box; */
    border: 5px solid #5cb85c;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
    border-radius: 0.375rem;
    box-shadow: none;
    transition: none;
}
.form-control:focus{
  box-shadow: none;
  border-color: #5cb85c;
  -webkit-box-shadow: none;
}
/* .has-error .form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
} */
/* input {
  box-shadow:none;
} */

button {
  color : white;
  background: #5cb85c;
  border:#5cb85c 5px;
  border-radius: 0.375rem;
}

.fs-1 {
  color : #5cb85c;
}

</style>
