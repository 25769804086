<template>
  <div>
    <h1>메인 탭</h1>
    <p>은밀한 취미탭에..은밀한 것이 있습니다
    </p>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sampleData: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
