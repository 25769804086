<template>
  <div>
    <img @click="this.$router.push('tap2')" src="@/assets/빠더빵배너1.png" style="width:100%">
  </div>
    <div class="text-bg-dark p-3 clearfix">
      <span @click="this.$router.push('tap1')" class="float-start">
        빠더빵소개
      </span>
      <span @click="this.$router.push('tap2')" class="float-start">
        상품목록
      </span>
      <span @click="this.$router.push('tap3')" class="float-start">
        공지사항
      </span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span @click="this.$router.push('cart')" class="float-end">
        장바구니
      </span>
      <span @click="this.$router.push('login')" class="float-end">
        로그인
      </span>
    </div>
  <router-view/>

</template>
<script>
export default {
  components: {},
  data() {
    return {
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<style scoped>
span{
  padding: 2%;
}
</style>
