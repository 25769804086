<template>
  <nav>
    <!-- <router-link to="/">Home</router-link> | -->
    <!-- <router-link to="/about">About</router-link> -->
  </nav>
  <router-view/>
</template>
<script>
import 'element-plus/dist/index.css'
export default {
  components: {
  },
  data() {
    return {
      sampleData: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

.openthemodal-enter-from {
  opacity: 0;
}
.openthemodal-enter-active {
  transition: all 1s;
}
.openthemodal-enter-to {
  opacity: 1;
}
</style>
