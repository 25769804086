<template>
  <div>
    <!-- Button trigger modal -->
  <div id="black-bg" style="position: fixed; z-index:2; top:0px;" v-if="modalopen == false" >
  <div class="white-bg w-50 p-3 position-absolute top-50 start-50 translate-middle" style="width:50%">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">{{  $store.state.bakerylist[whatnum].title  }}</h1>
        <button @click="$emit('modalcolse')" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body"> <hr>
      <div class="ratio" style="--bs-aspect-ratio: 50%;">
            <img :src="$store.state.bakerylist[whatnum].image">
            </div>
        <hr>
        <h5 class="text-center">{{ $store.state.bakerylist[whatnum].content }}</h5>
        <hr>
        <form>
          <span class="fs-5">상품수량 : </span>
        <div class="btn-group me-2" role="group" aria-label="Second group">
    <button @click="eanum--" type="button" class="btn btn-secondary">-</button>
    <input v-model="eanum" style="width: 40px; text-align: center;" class="btn-light">
    <button @click="eanum++" type="button" class="btn btn-secondary">+</button>
    </div>
    </form>
        <hr>
        <span class="fs-5">총 금액 : {{ $store.state.bakerylist[whatnum].price * eanum }} 원</span>
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-primary">장바구니 담기</button>
        <button @click="$emit('modalcolse')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">닫기</button>
        </div>
        </div>
  </div>
</div>
</div>
</div>
<!-- Modal -->
</template>
<script>
export default {
  components: {},
  data() {
    return {
      eanum: 1
    }
  },
  watch: {
    eanum(a) {
      if (a <= 0) {
        alert('수량은 한 개 이상만 가능합니다.')
        this.eanum = 1
      }
    }
  },
  props: {
    whatnum: Number,
    modalopen: Boolean
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
  }
}
</script>

<style scoped>
body {
  margin : 0;
}
div {
  box-sizing: border-box;
}
#black-bg {
  width: 100%; height:100%;
  position: fixed; padding: 20px;
  background: rgba(0,0,0,0.5);
}
.white-bg {
  width: 100%; background: white;
  border-radius: 8px;
  padding: 20px;
}

.text-wrap{
  position: center;
}
.text-center{
  margin-left: 10%;
  margin-right: 10%;
  padding: 2%;
}
</style>
