import { createStore } from 'vuex'
import bakerylist from '@/assets/bakerylist.js'

export default createStore({
  state: {
    bakerylist,
    bakerylistOrigin: [...bakerylist],
    cartItems: []
  },
  getters: {
  },
  mutations: {

    priceSort(state) {
      state.bakerylist.sort(function(a, b) {
        return a.price - b.price
      })
    },
    invenSort(state) {
      state.bakerylist.sort(function(a, b) {
        return a.id - b.id
      })
    },
    addCartItem(state, cartItem) {
      state.cartItems.push(cartItem)
    }
  },
  actions: {
  },
  modules: {
  }
})
