<template>
<event/>
<bakerysort/>
  <div class="bakerytap2">
    <Transition name="openthemodal">
    <Modal @modalcolse="modalopen = true" :whatnum="whatnum" :modalopen="modalopen"/>
    </Transition>
    <div class="row row-cols-1 row-cols-md-2 g-4" style="padding:3% z-index:1;" >
      <div v-for="(a, i) in $store.state.bakerylist" :key="i" class="col">
        <div class="card h-100">
          <img @click="modalopen=false; whatnum=i"
            :src="$store.state.bakerylist[i].image"
            alt="$store.state.bakerylist[i].image"
            style="height: 300px"
          />
          <div class="card-body">
            <h5 class="card-title">{{ $store.state.bakerylist[i].title }}</h5>
            <p class="card-text">{{ $store.state.bakerylist[i].price }}원</p>
          </div>
          <div class="card-footer">
            <small class="text-muted"> 잔여 재고 : {{$store.state.bakerylist[i].id}}</small>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import event from '@/components/event.vue'
import bakerysort from '@/components/bakerysort.vue'
export default {
  components: {
    Modal,
    event,
    bakerysort
  },
  data() {
    return {
      whatnum: 0,
      modalopen: true
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
  }
}
</script>

<style scoped>
</style>
