import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import ElementPlus from 'element-plus'
import ko from 'element-plus/es/locale/lang/ko'
const app = createApp(App)
app.config.globalProperties.axios = axios

app
  .use(store)
  .use(router)
  .use(ElementPlus, { locale: ko })
  .mount('#app')
